<template>
  <div class="main-create-container">
    <div v-if="current === 'purchase'" class="purchase-container">
      <h4 class="my-1">{{ purchase ? `# ${purchase.id}` : '' }} {{ purchaseName || 'Новая закупка' }}</h4>
      <div class="input-purchase-name mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1">Название</span>
        <b-form-input
            class="purchase-input"

            v-model="purchaseName"
            placeholder="название закупки"
        />
      </div>
      <div class="select-purchase-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Поставщик* </span>
        <div class="d-flex" style="max-width: 580px; width: 100%;">
          <b-form-select

              class="purchase-input mr-1"
              v-model="purchaseContractor"
              :options="contractors.map(item=>({value: item.name, text: item.name}))"
          >
          </b-form-select>
          <b-button
              @click="current='contractor'"
              variant="outline-secondary"
              class="btn-icon"
          >
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </div>
      </div>
      <div class="select-purchase-warehouse mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Склад </span>
        <div class="d-flex" style="max-width: 580px; width: 100%;">
          <b-form-select

              class="purchase-input mr-1"
              v-model="purchaseWarehouses"
              :options="warehouses.map(item=>({value: item.name, text: item.name}))"
              placeholder="склад"
          >
          </b-form-select>
          <b-button
              @click="current='warehouse'"
              variant="outline-secondary"
              class="btn-icon"
          >
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </div>
      </div>
      <div class="select-date-warehouse mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Дата закупки* </span>
        <b-form-datepicker
            id="purchaseDate"
            class="purchase-input"
            v-model="purchaseDate"
        />
      </div>
      <div class="buttons-purchase d-flex justify-content-end">
        <b-button
            :disabled="!(this.purchaseName && this.purchaseDate && this.purchaseContractor && this.purchaseContractor)"
            class="mr-1"
            @click="createPur"
            variant="outline-primary"
        >
          {{ purchase ? 'Сохранить' : 'Создать' }}
        </b-button>
        <b-button
            disabled
            variant="primary"
        >
          Добавить товары
        </b-button>
      </div>
    </div>
    <div v-if="current === 'warehouse'" class="warehouse-container">
      <h4 class="my-1">{{ 'Новый склад' }}</h4>
      <div class="input-name-warehouse mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Название* </span>
        <b-form-input
            class="purchase-input"
            v-model="warehouseName"
            placeholder="название склада"
        />
      </div>
      <div class="select-contractor-warehouse mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Контрагент* </span>
        <b-form-select
            class="purchase-input"
            v-model="contractorWarehouse"
            :options="contractors.map(item=>({value: item.name, text: item.name}))"
        >
        </b-form-select>
      </div>
      <div class="address-warehouse mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Адрес* </span>
        <b-form-input
            class="purchase-input"
            v-model="addressWarehouse"
            placeholder="адрес склада"
        />
      </div>
      <div class="button-warehouse d-flex justify-content-between">
        <b-button

            @click="current='purchase'"
            variant="outline-primary"
        >
          Назад
        </b-button>
        <b-button
            :disabled="!(warehouseName && contractorWarehouse && addressWarehouse)"
            variant="primary"
            @click="createWarehouse"
        >
          Создать
        </b-button>
      </div>
    </div>
    <div v-if="current === 'contractor'" class="contractor-container">
      <h4 class="my-1">{{ 'Новый контрагент' }}</h4>
      <div class="name-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Имя* </span>
        <b-form-input
            class="purchase-input"
            v-model="contractorName"
            placeholder="имя контрагента"
        />
      </div>
      <div class="select-contractor-type mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Тип контрагента* </span>
        <b-form-select

            class="purchase-input"
            v-model="contractorType"
            :options="typeContractors.map(item=>({value: item, text: item}))"
        >
        </b-form-select>
      </div>

      <div class="inn-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> ИНН </span>
        <b-form-input
            class="purchase-input"
            v-model="contractorInn"
            placeholder="ИНН контрагента"
        />
      </div>
      <div class="kpp-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> КПП </span>
        <b-form-input
            class="purchase-input"
            v-model="contractorKpp"
            placeholder="КПП контрагента"
        />
      </div>
      <div class="address-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Физ. адрес </span>
        <b-form-input
            class="purchase-input"
            v-model="addressContractor"
            placeholder="фактический адрес контрагента"
        />
      </div>
      <div class="legal-address-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Юр. адрес </span>
        <b-form-input
            class="purchase-input"
            v-model="legalContractorAddress"
            placeholder="юридический адрес контрагента"
        />
      </div>

      <div class="button-contractor d-flex justify-content-between">
        <b-button

            @click="current='purchase'"
            variant="outline-primary"
        >
          Назад
        </b-button>
        <b-button
            @click="createContractor"
            variant="primary"
            :disabled="!(contractorName && contractorType)"
        >
          Создать
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import {VBPopover} from "bootstrap-vue";
import {
  BButton,
  BCard,
  BTable,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BSpinner,
  BModal,
  BFormSelectOption,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  VBModal,
} from 'bootstrap-vue';
import {mapActions, mapState} from "vuex";

export default {
  name: "PurchaseCreate",
  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,
    BSpinner,
    BButton,
    BPagination,
    BFormDatepicker,
    BFormSelectOption,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      purchaseId: this.purchase?.id || null,
      stateWarehouseWrite: null,
      contractorType: '',
      current: 'purchase',
      purchaseName: this.purchase?.name || null,
      purchaseWarehouses: this.purchase?.warehouse || null,
      purchaseDate: this.purchase?.date_purchase || null,
      purchaseContractor: this.purchase?.contractor || null,
      warehouseName: '',
      contractorWarehouse: '',
      contractorName: '',
      addressContractor: '',
      contractorInn: '',
      legalContractorAddress: '',
      contractorKpp: '',
      addressWarehouse: ''
    }
  },

  props: {
    purchase: Object
  },

  directives: {
    'b-popover': VBPopover
  },

  computed: {
    ...mapState('unitReport', ['subSortToOperation']),
    ...mapState('purchase', ['contractors', 'warehouses', "typeContractors"]),

    getEditPurchase() {

    },

    getDate() {
      if (this.date) {
        let date = new Date(this.date);
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },
    getTime() {
      if (this.date) {
        let date = new Date(this.date);
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let min = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${min}`;
      }
      return ''
    }
  },

  methods: {
    async createWarehouse() {
      this.stateWarehouseWrite = 0;
      this.stateWarehouseWrite = await this.addWarehouse({name: this.warehouseName, address: this.addressWarehouse})
      setTimeout(() => {
        if (this.stateWarehouseWrite) {
          this.stateWarehouseWrite = null
          this.current = 'purchase'
        }
      }, 3000);
    },

    async createContractor() {
      this.stateContractor = 0;
      this.stateContractor = await this.addContractor({
        name: this.contractorName,
        address: this.addressWarehouse,
        legalAddress: this.legalContractorAddress,
        inn: this.contractorInn,
        kpp: this.contractorKpp,
        type: this.contractorType
      })

      setTimeout(() => this.stateContractor = null, 3000)
      if (this.stateContractor) {
        this.current = 'purchase'
      }
    },

    async createPur() {
      // status, name, datePurchase, contractorName, warehouseName
      if (this.purchaseDate && this.purchaseContractor) {
        let id = await this.createPurchase({
          name: this.purchaseName,
          purchaseId: this.purchaseId,
          datePurchase: this.purchaseDate,
          contractorName: this.purchaseContractor,
          warehouseName: this.purchaseWarehouses
        });

        if (this.subSortToOperation.length) {
          const products = this.subSortToOperation.map(prod => ({
            nmId: prod.nmID,
            chrtID: prod.chrtId,
            amount: prod.sub_sort_value
          }))
          await this.prependProducts({purchaseId: id, products});
          this.clearSubSortToOperation();
        }

        await this.loadListPurchase();
        this.$bvModal.hide('add_purchase')
      }
    },

    ...mapActions('purchase', ["addContractor", "addWarehouse", "createPurchase", "loadListPurchase"]),
    ...mapActions('purchaseItem', ["prependProducts", "clearProductsToAdd"]),
    ...mapActions('unitReport', ["clearSubSortToOperation"])
  }

}
</script>

<style scoped>

.date-order {
  text-align: center;
}

.purchase-input {
  max-width: 580px;
}

.order-status-icon {

}

</style>