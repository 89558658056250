<template>
  <div class="cell-show-detail mr-1">
    <div class="btn-detail-cont cursor-pointer"
    >
      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-if="setState"
      >
        <path d="M15.8213 1.07129L8.92044 7.87056C8.88191 7.90853 8.82017 7.90897 8.7811 7.87156L1.67915 1.07129"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-else
      >
        <path d="M0.75 1L7.54927 7.90085C7.58724 7.93938 7.58768 8.00112 7.55027 8.04019L0.75 15.1421"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
    </div>
  </div>
</template>

<script>

import {
  BButton,
} from 'bootstrap-vue';

export default {
  name: "cellShowDetail",
  components: {},

  data() {
    return {
      open: false
    }
  },

  computed: {

  },

  props: {
    setState: Boolean
  },

  methods: {
    openDetail() {
      this.open = !this.open
      this.$emit('state', this.open);
    }
  },

  watch: {}
}
</script>

<style scoped>
.btn-detail-cont {
  width: 20px;
  height: 20px;
}
</style>