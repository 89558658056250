import { render, staticRenderFns } from "./cellShowDetail.vue?vue&type=template&id=21059564&scoped=true&"
import script from "./cellShowDetail.vue?vue&type=script&lang=js&"
export * from "./cellShowDetail.vue?vue&type=script&lang=js&"
import style0 from "./cellShowDetail.vue?vue&type=style&index=0&id=21059564&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21059564",
  null
  
)

export default component.exports