<template>
  <div class="table-orders-container">
    <b-table
        id="table-purchase-products"
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getListPurchases"
        :current-page="currentPage"
        :per-page="10">
      <template #cell(name)="data">
        <name-purchase-product
            :product="data.item"
        />
      </template>
      <template #cell(money)="data">
        <div style="width: 95px">
          {{ data.item.itemPrice }}
        </div>
      </template>
      <template #cell(amount)="data">
        <div style="width: 81px">
          {{ data.item.amount }}
        </div>
      </template>
      <template #cell(warehouse)="data">
        <div style="width: 100px"></div>
      </template>
      <template #cell(date)="data">
        <div style="width: 120px"></div>
      </template>
      <template #cell(status)="data">
        <div style="width: 100px"></div>
      </template>
      <template #cell(contractor)="data">
        <div style="width: 120px"></div>
      </template>

    </b-table>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BTable,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BSpinner,
  BModal,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  VBModal,
} from 'bootstrap-vue';
import OrderStatus from './OrderStatus';
import NamePurchaseProduct from './NamePurchaseProduct';
import ProductCellSimple from '../ProductCellSimple';
import PopUpSMV from './PopUpSMV';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TablePurchaseProducts",
  components: {
    ProductCellSimple,
    NamePurchaseProduct,
    BCard,
    BTabs,
    BTab,
    BCardText,
    BSpinner,
    BButton,
    BPagination,
    BFormDatepicker,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    OrderStatus,
    PopUpSMV
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      showSMVModal: false,
      fields: [
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'money',
          label: 'Объем Руб',
        },
        {
          key: 'amount',
          label: 'Объем шт',
        },
        {
          key: 'status',
          label: 'Статус',
        },
        {
          key: 'contractor',
          label: 'Поставщик',
        },
        {
          key: 'warehouse',
          label: 'Склад',
        },
        {
          key: 'date',
          label: 'Дата закупки',
        }
      ],
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    }
  },

  methods: {
    getDate(date) {
      if (date) {
        date = new Date(date);
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },
    ...mapActions('purchase', ["loadListPurchase"]),
  },

  computed: {
    getListPurchases() {
      return this.products
    }
  },

  watch: {},

  async mounted() {

  },
}
</script>

<style scoped>

.load-orders {
  font-size: 28px;
  margin: 10px;
  color: #c0bcbc;
  text-align: center;
}
</style>