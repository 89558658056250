<template>
  <div class="d-flex name-purchase-product" style="width: 300px">
    <b-img left class="mr-1 mb-sm-0" height="40" :src="product.url" alt="Left image')"/>
    <div>
      <div>{{ product.title }}</div>
      <div>{{ product.nmId }}</div>
    </div>
  </div>
</template>

<script>

import {
  BImg,
} from 'bootstrap-vue';

export default {
  name: "NamePurchaseProduct",
  components: {
    BImg
  },

  data() {
    return {
      open: false
    }
  },

  props: {
    product: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {},

  methods: {},

  watch: {}
}
</script>

<style scoped>
.load_products {
  color: #d9d9d9;
  text-align: center;
  font-size: 44px;
  margin: 54px 0 18px 0;
}
</style>